import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { useInView } from "react-intersection-observer"
import { useGlobalDispatchContext } from "../../utilities/context"
import useWindowType from "../../utilities/useWindowType"

import { Flex } from "../objects"
import Form from "../form"

import SwitchLogo from "../../assets/images/logos/switch40-logo-full-color.svg"
import Arrow from "../../assets/images/icons/arrow-up-gold.png"
import ArrowDiagonal from "../../assets/images/icons/arrow-diagonal-gold.png"
import WhatsApp from "../../assets/images/icons/whatsapp.svg"
import FB from "../../assets/images/icons/fb.svg"
import Twitter from "../../assets/images/icons/tw.svg"

const Wrap = styled.footer`
  min-height: 100vh;
  padding: 14.908vw 13.685vw 5vw;
  position: relative;

  &.portrait {
    min-height: 90vw;
  }
`

const GoTop = styled.button`
    position: absolute;
    bottom: 1.376vw;
    right: 1.376vw;
    height: 7.339vw;
    width: 7.339vw;
    border: solid 1px ${props => props.theme.blue};
    border-radius: 100%; 
    z-index: 2; 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.25s;

    &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${props => props.theme.blue};
        transition: transform 0.2s ease-in-out;
        transform: scale3d(0,0,0);
        border-radius: 100%; 
        z-index: -1;
    }

    &:before{
        content: "";
        position: absolute;
        height: 1.25rem;
        width: 1.25rem;
        background-size: contain; 
        background-image: url('${Arrow}');
        background-position: center;    
        background-repeat:no-repeat;
        z-index: 1;
        transition: transform 0.5s;
    }

    &:hover{
        transform: scale3d(1.1,1.1,1.1);

        &:after{
            transform:scale3d(1,1,1);
        }

        &:before{
            transform: translateY(-1rem); 
        }
    }
    
    @media (max-width: 767px){
        bottom:6.4vw;
        right: 6.4vw;
        height: 14vw;
        width: 14vw;
    }
`

const Sitemap = styled.div`
  a {
    display: block;
    padding: 0.75rem 0;
    color: ${props => props.theme.blue};
    position: relative;
    transition: opacity 0.5s;

    &:after {
      content: "";
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      left: -2rem;
      top: 40%;
      background-image: url(${ArrowDiagonal});
      background-size: cover;
      transition: transform 0.5s;
      transform-origin: 0 0;
    }

    &:hover {
      opacity: 0.5;

      &:after {
        transform: translate(1rem, -0.25rem) rotate(45deg);
      }
    }
  }
`

const Contacts = styled.section`
  padding-top: 4.422vw;
  p {
    padding-bottom: 1.5rem;

    &::last-of-type {
      padding-bottom: 0;
    }
  }

  a {
    display: block;
    padding: 0.25rem 0;
  }

  .underline {
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.gold};
  }
`

const Info = styled.div`
  width: 23.394vw;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 12vw;
  }
`

const FormWrap = styled.form`
  width: 35.703vw;

  p {
    font-size: 0.875rem;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Logo = styled.figure`
  width: 10.087vw;
  height: 2.479vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${SwitchLogo});

  @media (max-width: 767px) {
    width: 24vw;
    height: 10vw;
  }
`

const Socials = styled.div`
  position: relative;
  top: 0.375rem;

  img {
    height: 1.35rem;
    width: 1.35rem;
    object-fit: contain;
  }

  a {
    display: inline-block;
    padding-left: 2.5rem;
  }

  @media (max-width: 767px) {
    top: 3.5vw;

    img {
      height: 4vw;
      width: 4vw;
    }
  }
`

const LogoWrap = styled.div`
  padding-top: 5.615vw;

  @media (max-width: 767px) {
    padding-top: 12vw;
  }
`

const Legal = styled.p`
  width: 35.703vw;
  padding-top: 6.04vw;
  margin-left: 36.927vw;

  a {
    padding-left: 0.25rem;
    display: inline-block;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding-top: 15vw;
    padding-bottom: 25vw;
    font-size: 0.875rem;
    opacity: 0.75;
  }
`

export default () => {
  const windowType = useWindowType()

  //scroll to top
  let scrollToY = (scrollTargetY, speed, easing) => {
    var scrollY = window.scrollY || document.documentElement.scrollTop,
      scrollTargetY = scrollTargetY || 0,
      speed = speed || 2000,
      easing = easing || "easeOutSine",
      currentTime = 0

    var time = Math.max(
      0.1,
      Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
    )

    var easingEquations = {
      easeOutSine: function (pos) {
        return Math.sin(pos * (Math.PI / 2))
      },
      easeInOutSine: function (pos) {
        return -0.5 * (Math.cos(Math.PI * pos) - 1)
      },
      easeInOutQuint: function (pos) {
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 5)
        }
        return 0.5 * (Math.pow(pos - 2, 5) + 2)
      },
    }

    function tick() {
      currentTime += 1 / 60

      var p = currentTime / time
      var t = easingEquations[easing](p)

      if (p < 1) {
        requestAnimationFrame(tick)

        window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t)
      } else {
        console.log("scroll done")
        window.scrollTo(0, scrollTargetY)
      }
    }

    tick()
  }

  //toggle global state
  const dispatch = useGlobalDispatchContext()

  const [footer, footerInView] = useInView()

  useEffect(() => {
    if (footerInView) {
      dispatch({
        type: "toggle_menu_view",
        menuView: "hidden",
      })

      dispatch({
        type: "toggle_logo_view",
        logoView: "hidden",
      })

      dispatch({
        type: "toggle_contact_view",
        contactView: "hidden",
      })
    } else {
      dispatch({
        type: "toggle_menu_view",
        menuView: "visible",
      })

      dispatch({
        type: "toggle_contact_view",
        contactView: "visible",
      })
    }
  }, [footerInView])

  //form handling
  const formKeys = {
    userName: "",
    userEmail: "",
    userMessage: "",
  }

  return (
    <Wrap ref={footer} className={windowType}>
      <Flex justify fluidOnMobile>
        <Info>
          <Sitemap>
            <Link to="/agency">
              <span>About us</span>
            </Link>
            <Link to="/find-work">
              <span>Find work</span>
            </Link>
            <Link to="/find-staff">
              <span>Find staff</span>
            </Link>
          </Sitemap>

          <Contacts>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/Carlingford+Dr,+Westcliff-on-Sea,+Southend-on-Sea,+Westcliff-on-Sea,+UK/@51.555208,0.6866338,17z/data=!3m1!4b1!4m8!1m2!2m1!1s173+Sunnyhurst,+Carlingford+Drive,+West+Cliff+on+sea,+SS0+0SE!3m4!1s0x47d8d90a828281cb:0xe1a32e1e506cce7a!8m2!3d51.5552047!4d0.6888225"
              >
                <span>
                  173 Sunnyhurst, Carlingford Drive, West Cliff on sea, SS0 0SE,
                  UK
                </span>
              </a>
            </p>
            <p>
              <a href="mailto:ask@switch40.com">
                <span>ask@switch40.com</span>
              </a>
              <a href="tel:+443335779443">
                <span>Call 0333 577 9443</span>
              </a>
            </p>

            <p>
              <a href="https://wa.me/447542684971" rel="noreferrer">
                <span>
                  WhatsApp available 24/7:{" "}
                  <i className="underline">+447542684971</i>
                </span>
              </a>
            </p>
          </Contacts>
        </Info>

        <FormWrap
          name="contact"
          netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <input name="bot-field" type="hidden" />

          <Form formName="contact" formKeys={formKeys}>
            <div className="form-row">
              <label>
                <span>Name</span>
                <input
                  className="form-input"
                  type="text"
                  required
                  name="userName"
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                <span>Email</span>
                <input className="form-input" type="email" name="userEmail" />
              </label>
            </div>

            <div className="form-row clear-bottom">
              <label>
                <span>Message</span>
                <textarea
                  rows="1"
                  className="form-input"
                  name="userMessage"
                ></textarea>
              </label>
            </div>

            <div className="form-row">
              <button>
                <span>Send</span>
                <figure></figure>
              </button>
            </div>
            <p className="legal">
              *By clicking 'Submit', you agree with the handling of your data in
              accordance with the Switch40{" "}
              <Link to="/privacy-policy">privacy policy.</Link>
            </p>
          </Form>
        </FormWrap>
      </Flex>

      <LogoWrap>
        <Flex justify>
          <Logo />
          <Socials>
            <a
              href="https://www.facebook.com/Switch40LTD-104681604545080/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={FB} alt="facebook" />
            </a>
            <a
              href="https://twitter.com/Switch401?s=08"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
            <a
              href="https://wa.me/447542684971"
              target="_blank"
              rel="noreferrer"
            >
              <img src={WhatsApp} alt="whatsapp" />
            </a>
          </Socials>
        </Flex>
      </LogoWrap>

      <Legal>
        Copyright 2020. Switch40.{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>
      </Legal>

      <GoTop
        onClick={() => {
          scrollToY(0, 2000, "easeInOutQuint")
        }}
      />
    </Wrap>
  )
}
