import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { useGlobalStateContext } from "../../utilities/context"

import Phone from "../../assets/images/icons/phone.png"

const Box = styled.nav`
  position: fixed;
  right: 1.376vw;
  top: 7.339vw;
  z-index: 10;
  transition: transform 1.25s cubic-bezier(0.25, 1, 0.5, 1), visibility 0.5s,
    opacity 0.5s;

  ${props =>
    props.view === "visible" &&
    css`
      transition-delay: 0.25s;
      transform: translate3d(0, -5.416vw, 0);
    `}

  ${props =>
    props.view === "hidden" &&
    css`
      a.contact {
        color: ${props => props.theme.blue};
      }
    `}

    @media (max-width: 767px) {
    top: 0;
    right: 0;
    width: 100%;
    transform: none !important;
    display: flex;
    justify-content: space-between;

    ${props =>
      props.view === "visible" &&
      css`
        a {
          opacity: 1 !important;
          visibility: visible !important;
        }
      `}
  }
`

const MenuItem = styled.div`
  display: inline-block;
  margin-right: 0.75rem;
  position: relative;
  top: -0.85rem;

  figure {
    display: inline-block;
    height: 0.875rem;
    width: 0.875rem;
    background-image: url(${Phone});
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 1px;
    margin-right: 0.5rem;
  }

  a {
    display: block;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    color: #fff;
    position: relative;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;

    ${props =>
      props.delay &&
      css`
        transition-delay: ${props => props.delay}s;
      `}

    ${props =>
      props.view === "visible" &&
      css`
        visibility: visible;
        opacity: 1;
      `}

        &.is-active {
      &:after {
        content: "";
        height: 0.4rem;
        width: 0.4rem;
        position: absolute;
        top: 80%;
        left: 50%;
        border-radius: 0.5rem;
        background: ${props => props.theme.gold};
      }
    }

    &.contact {
      opacity: 1;
      visibility: visible;

      ${props =>
        props.view === "hidden" &&
        css`
          opacity: 0;
          visibility: hidden;
        `}
    }
  }

  @media (max-width: 767px) {
    top: 0;
    margin: 0;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      padding: 1.75rem 1rem;

      &.contact {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
`

const Strip = styled.span`
  position: absolute;
  top: -2.5vw;
  width: 110%;
  height: 6.5vw;
  background-color: ${props => props.theme.blue};
  right: -1.376vw;
  z-index: -1;

  transition: transform cubic-bezier(0.25, 1, 0.5, 1) 0.75s;
  transform-origin: 100% 0;
  transform: scale3d(0, 1, 1);

  ${props =>
    props.view === "visible" &&
    css`
      transform: scale3d(1, 1, 1);
    `}

  @media (max-width: 767px) {
    top: 0;
    height: 100%;
    width: 100%;
    right: 0;
  }
`

export default () => {
  const { menuView, contactView } = useGlobalStateContext()

  return (
    <>
      <Box view={menuView}>
        <MenuItem delay={0.1} view={menuView}>
          <Link to="/find-work" activeClassName="is-active">
            <span>Find Work</span>
          </Link>
        </MenuItem>
        <MenuItem delay={0.1} view={menuView}>
          <Link to="/find-staff" activeClassName="is-active">
            <span>Find Staff</span>
          </Link>
        </MenuItem>
        <MenuItem delay={0.2} view={menuView}>
          <Link to="/agency" activeClassName="is-active">
            <span>About</span>
          </Link>
        </MenuItem>
        <MenuItem view={contactView}>
          <a
            target="_blank"
            rel="noreferrer"
            href="tel:+443335779443"
            className="contact"
          >
            <figure className="phone"></figure>
            <span>0333 577 9443</span>
          </a>
        </MenuItem>
        <Strip view={menuView} />
      </Box>
    </>
  )
}
