import styled, { css } from "styled-components"
import Check from "../assets/images/icons/check.png"

export const Img = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

export const Flex = styled.div`
    display: flex;

    ${props =>
      props.justify &&
      css`
        justify-content: space-between;
      `}


    ${props =>
      props.reverse &&
      css`
        flex-direction: row-reverse;
      `}

    ${props =>
      props.fluidOnMobile &&
      css`
        @media (max-width: 767px) {
          display: block;
        }
      `}
`

export const PageSectionWrap = styled.section`
  width: 74.006vw;
  background: ${props => props.theme.blue};
  color: #fff;
  float: right;
  padding-bottom: 12.703vw;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    z-index: 10;
    width: 21.942vw;
    right: 0;
    bottom: -3.23vw;
    background: ${props => props.theme.kaki};
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }

  ${props =>
    props.bkg === "gold" &&
    css`
      background: ${props => props.theme.kaki};
      color: ${props => props.theme.blue};
    `}

  @media (max-width: 767px) {
    width: 100%;
    padding-left: 6.4vw;
    padding-right: 6.4vw;
    float: none;
    padding-bottom: 40vw;
    overflow: hidden;
  }
`

export const PageBkg = styled.section`
  background-color: ${props => props.theme.grey};

  &:after {
    content: "";
    display: table;
    clear: both;
  }
`

export const Story = styled.section`
  width: 50%;
  margin-left: 12.309vw;

  p {
    padding-bottom: 6.804vw;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  ul {
    margin-top: 7.5vw;
  }

  .portrait & {
    width: 55%;
    margin-left: 10%;
  }

  &.privacy-policy {
    width: 75%;

    h2 {
      font-size: 1.5rem;
      padding-bottom: 2vw;
      margin: 2vw auto;
    }

    li,
    p {
      padding-bottom: 1.5rem;
    }

    ul {
      width: 80%;
      margin: 3rem auto;
      list-style: disc outside;
      line-height: 2;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;

    ul {
      width: 90%;
      margin: 10vw auto;
    }
  }
`

export const PageForm = styled.form`
  width: 50%;
  position: relative;
  padding-top: 10.804vw;
  margin-left: 12.309vw;

  .portrait & {
    width: 55%;
    margin-left: 10%;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }
`

export const PageTitle = styled.h1`
  width: 65%;
  padding-top: 14.144vw;
  margin-left: 12.309vw;
  padding-bottom: 7.722vw;
  color: ${props => props.theme.cream};

  ${props =>
    props.dark &&
    css`
      color: ${props => props.theme.black};
    `}

  span {
    display: block;

    &:first-child {
      color: #fff;
      font-size: 1rem;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }
  }

  .portrait & {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 22vw 0;
  }
`

export const List = styled.ul`
  li {
    padding: 1.25rem 0;
    border-top: solid 1px ${props => props.theme.kaki};
    position: relative;

    &:after {
      content: "";
      height: 1rem;
      width: 1rem;
      position: absolute;
      top: 40%;
      left: -2rem;
      background-image: url(${Check});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:last-child {
      border-bottom: solid 1px ${props => props.theme.kaki};
    }
  }
`
