import React, { useRef, useEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"

import Arrow from "../assets/images/icons/arrow-ltr-gold.png"
import Spinner from "../assets/images/icons/spinner.svg"
import Check from "../assets/images/icons/tick.svg"
import Error from "../assets/images/icons/error.svg"
import Dropdown from "../assets/images/icons/dropdown.png"

const Spin = keyframes`
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
`

const Wrap = styled.div`
    position: relative;
    color: inherit;

    &.is-submitting{
        label, input, textarea, button, p {
            opacity: 0.25;
            pointer-events: none;
            cursor:none;

            &:hover{
                opacity: 0.25 !important;
            }
        }

        .form-loader{
            opacity: 1;
            visibility: visible;

            figure.spinner{
                animation-play-state: running;
                display: block;
            }
        }

    }

    &.is-submitted{
        label, input, textarea, button, p {
            opacity: 0 !important;
        }

        .form-loader{
            opacity: 1;
            visibility: visible;

            figure.tick {
                display: block;
            }

            .message{
                display: block;
            }
        }
    }

    &.is-error{
        .form-loader{
            opacity: 1;
            visibility: visible;

            figure.error {
                display: block;
            }

            .message{
                display: block;
            }
        }
    }
    
    .form-loader{
        height: 100%; 
        width: 100%; 
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        
        transition: opacity 0.5s;
        visibility: hidden; 
        opacity: 0;

        .message {
            font-size: 1.125rem;
            display: none;
            margin-top: 1rem;
        }

        figure {
            width: 3rem;
            height: 3rem;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display:none;

            &.spinner {
                background-image: url('${Spinner}');
                animation: ${Spin} 0.85s linear infinite;
                animation-play-state: paused;
            }

            &.tick {
                background-image: url('${Check}');
            }

            &.error {
                background-image: url('${Error}');
            }
        }
    }
    
    .form-row {
        margin-bottom:1.5rem;

        &.is-filled, &.is-active{
            label{
                span {
                    transform: scale3d(0.75,0.75,0.75) translate3d(0, -0.5rem, 0);
                    opacity: 0.5;
                }
            }
        }

        &.is-active{
            input,textarea {
                border-bottom-color: ${props => props.theme.gold};
            }
        }

        &.clear-bottom{
            margin-bottom: 0.5rem;
        }

        &:last-of-type{
            margin-bottom: 2.25rem;
        }
    }

    label {
        span {
            position: absolute;
            pointer-events: none;
            transition: transform 0.3s ease-in-out;
            transform-origin: 0 0;
            transform:  translate3d(0, 0.75rem, 0);
            display: block;
        }
    }

    input,textarea, button, select {
        border-bottom: solid 1px ${props => props.theme.kaki};
        width: 100%; 
        color: inherit;
        padding:1rem 0 0.75rem;
    }

    select {
        background-image: url('${Dropdown}');
        background-size: 1rem;
        background-repeat:no-repeat;
        background-position: right center;
    }

    button{
        text-align: left;
        padding-bottom: 1.125rem;
        position: relative;

        transition: opacity 0.3s;

        span {
            display: block;
        }

        figure {
            position: absolute;
            right: 0;
            height: 1rem;
            width: 1rem;
            background-image: url('${Arrow}');
            background-size: cover; 
            background-repeat: no-repeat;
            top: 41%; 
            transition: transform 0.5s, opacity 0.5s;
        }

        &:hover{
            opacity: 0.5;

            figure{
                transform:translate3d(1rem, 0, 0);
            }
        }
    }
    
    h3 {
        font-size: 1.75rem;
        padding-bottom: 3.618vw;
    }

    .form-header{
        padding-bottom: 4vw;
    }

    .legal {
        font-size: 0.875rem;
        opacity: 0.75;
        padding-top: 2vw;

        a {
            text-decoration: underline;
        }
    }


    ${props =>
      props.mode === "light" &&
      css`
        input,
        textarea,
        button,
        select {
          border-bottom: solid 1px ${props => props.theme.gold};
        }
      `}

    @media (max-width: 767px) {

        h3 {
            padding-bottom: 8vw;
        }

        .form-header {
            padding-bottom: 11vw;
        }
    }
`

export default ({ mode, children, formName, formKeys }) => {
  const form = useRef(null)

  const [formState, setFormState] = useState()
  const [formMessage, setFormMessage] = useState()

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  useEffect(() => {
    let keys = formKeys
    let name = formName

    form.current.querySelectorAll(".form-input").forEach((ele, index) => {
      //touch events
      const row = ele.parentNode.parentNode

      ele.addEventListener("focus", () => {
        row.classList.add("is-active")
      })

      ele.addEventListener("blur", () => {
        if (ele.value === "") {
          row.classList.remove("is-active")
        } else {
          row.classList.remove("is-active")
          row.classList.add("is-filled")
        }
      })

      ele.addEventListener("change", e => {
        keys[e.target.name] = e.target.value
      })
    })

    //submission
    form.current.closest("form").addEventListener("submit", e => {
      e.preventDefault()

      setFormState("is-submitting")

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": name, ...keys }),
      })
        .then(() => {
          setFormMessage("Message Sent")
          setFormState("is-submitted")

          setTimeout(() => {
            form.current.closest("form").reset()

            form.current.querySelectorAll(".form-input").forEach(ele => {
              ele.parentNode.parentNode.classList.remove("is-filled")
            })

            setFormState()
          }, 2200)
        })
        .catch(error => {
          setFormMessage("Please try again")
          setFormState("is-error")

          setTimeout(() => {
            setFormState()
          }, 2200)
        })
    })
  }, [])

  return (
    <Wrap ref={form} mode={mode} className={formState}>
      <div className="form-loader">
        <figure className="spinner"></figure>
        <figure className="tick"></figure>
        <figure className="error"></figure>
        <span className="message">{formMessage}</span>
      </div>
      {children}
    </Wrap>
  )
}
