import { useState, useEffect } from "react"
export default function useWindowType() {
  function getType() {
    if (typeof window !== "undefined") {
      let width = window.innerWidth
      let height = window.innerHeight

      if (width > 767 && height > width) {
        return "portrait"
      } else {
        return "normal"
      }
    }
  }

  const [windowType, setWindowType] = useState()

  useEffect(() => {
    //initial render
    let width = window.innerWidth
    let height = window.innerHeight
    width > 767 && height > width
      ? setWindowType("portrait")
      : setWindowType("normal")

    //handleResize
    function handleResize() {
      setWindowType(getType())
    }
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowType
}
