import React from "react"

import { GlobalProvider } from "../../utilities/context"
import Reset from "../../utilities/reset"
import Theme from "../../utilities/theme"
// import Transition from '../../utilities/transition'

import Logo from "./logo"
import Menu from "./menu"
import Curtain from "./curtain"
import Footer from "./footer"

export default ({ children }) => {
  return (
    <GlobalProvider>
      <Theme>
        <Reset />
        <Logo />
        <Menu />
        {children}
        <Curtain>
          <figure></figure>
        </Curtain>
        <Footer />
      </Theme>
    </GlobalProvider>
  )
}
