import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { useGlobalStateContext } from "../../utilities/context"

import logoCream from "../../assets/images/logos/switch40-logo-cream.svg"
import logoColor from "../../assets/images/logos/switch40-logo-full-color.svg"
import useWindowType from "../../utilities/useWindowType"

const Box = styled.div`
    width: 11.086vw;
    height: 2.479vw;
    position: fixed;
    top: 7.416vw;
    left: 13.761vw;
    z-index: 5;
    visibility: visible;
    
    transition: transform 1.5s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.5s, visibility 0.5s; 

    a {
        position: absolute; 
        width: 100%;
        height: 100%;
    }

    figure {
        background-size: contain; 
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; 
        height: 100%;
        
        transition: opacity 0.5s;
        opacity: 0;
    }

    &.portrait {
        width: 15vw;
        height: 10vw;
    }

    ${props =>
      props.visible === "hidden" &&
      css`
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
      `}

    ${props =>
      props.theme === "cream" &&
      css`
        ${Cream} {
          opacity: 1;
        }
      `}

    ${props =>
      props.theme === "color" &&
      css`
        ${Color} {
          opacity: 1;
        }
      `}

    ${props =>
      props.position === "edge" &&
      css`
        transform: translate3d(-12.385vw, -5.416vw, 0);
      `}

    @media (max-width: 767px){
        width: 35.933vw;
        height: 12.024vw;
        top: 10.400vw;
        left: 6.4vw;
        position: absolute;
        transform: none;
        opacity: 1 !important;
        visibility: visible !important;

        ${props =>
          props.position === "edge" &&
          css`
            transform: translate3d(0, 11vw, 0);
          `}
    }
`

const Cream = styled.figure`
  background-image: url(${logoCream});
`

const Color = styled.figure`
  background-image: url(${logoColor});
`

export default () => {
  const { logoView, logoTheme, logoPosition } = useGlobalStateContext()

  return (
    <Box
      theme={logoTheme}
      visible={logoView}
      position={logoPosition}
      className={useWindowType()}
    >
      <Link to="/">
        <Cream />
        <Color />
      </Link>
    </Box>
  )
}
