import styled, { keyframes } from "styled-components"
import Spinner from '../../assets/images/icons/spinner.svg'

const FadeOut = keyframes`
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
`

const Spin = keyframes`
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
`

export default styled.div`
    height: 100vh; 
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #fff;
    position: fixed;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    figure {
        width: 5rem;
        height: 5rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url('${Spinner}');
        animation: ${Spin} 0.5s linear infinite;
        animation-play-state: running;
    }

    .is-loaded & {
        animation: ${FadeOut} 1s ease both;

        figure {
            animation-play-state: paused;
        }
    }
`
