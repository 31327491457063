import React from "react"
import { ThemeProvider } from "styled-components"

const royalTheme = {
  gold: "#a7620b",
  golder: "#9e5800",
  blue: "#090d3c",
  cream: "#fbefbb",
  kaki: "#E2B479",
  sky: "#DEE8FF",
  white: "#FFFFFF",
  black: "#05092f",
  grey: "#e4edf7",
}

export default ({ children }) => {
  return <ThemeProvider theme={royalTheme}>{children}</ThemeProvider>
}
