import React, { createContext, useReducer, useContext } from "react"

const defaultState = {
  logoTheme: "cream",
  logoView: "visible",
  logoPosition: "default",
  menuView: "hidden",
  contactView: "visible",
}

const GlobalStateContext = createContext(defaultState)
const GlobalDispatchContext = createContext()

const globalReducer = (state, action) => {
  switch (action.type) {
    case "toggle_logo_theme": {
      return {
        ...state,
        logoTheme: action.logoTheme,
      }
    }

    case "toggle_logo_view": {
      return {
        ...state,
        logoView: action.logoView,
      }
    }

    case "toggle_menu_view": {
      return {
        ...state,
        menuView: action.menuView,
      }
    }

    case "toggle_contact_view": {
      return {
        ...state,
        contactView: action.contactView,
      }
    }

    case "offset_logo": {
      return {
        ...state,
        logoPosition: action.logoPosition,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const GlobalProvider = ({ children }) => {
  let [state, dispatch] = useReducer(globalReducer, defaultState)

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

//custom hooks to use dispatch and sate
export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)
export const useGlobalStateContext = () => useContext(GlobalStateContext)
